import { useMutation, useQuery, useQueryClient } from "react-query";
import { User, UserData } from "../../store/types/UserTypes";
import BaseService from "../../services/api/BaseService";
import { UserUrls } from "../../services/api/urls";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";

export type UserCrudType = ['users', number, string, string, boolean, boolean]

export const default_users_key: UserCrudType = ['users', 1, '', 'date_added', false, false];

export const get_detail_user_key = (uuid: string) => ['user_details', uuid];

export const payload_users_key = ['users_current_key'];
export const useUsers = (page: number, keyword: string, order_field: string = 'date_added',isExisting:boolean,  client_uuid?: string, isMandant?: boolean, safe_access?: string, unactive?: boolean, no_role?: boolean,order?:string, 
    onComplete?: () => void, onLoading?: () => void
) => {

    const { t } = useTranslation();
    const queryClient = useQueryClient();
    const current_key = ['users', page, keyword, order_field, unactive, no_role];

    queryClient.setQueryData(payload_users_key, current_key);

    return useQuery<UserData, Error>(
        current_key,
        async ({ pageParam, queryKey: [key, page] }) => {
            let data: any;
            try {
                if (onLoading != undefined) {
                    onLoading()
                }
                const response = await BaseService.getRequest(
                    UserUrls.LIST_USERS({
                        page: (page as number),
                        keyword: (keyword as string),
                        client_uuid:(client_uuid as string),
                        per_page:isExisting ? 100000000000000 : 10,
                        order_field,
                        unactive,
                        no_role,
                        mandant: isMandant || false,
                        safe_access: safe_access
                    }),
                    true
                )
                data = await response.json();
                if ([200, 201].includes(response.status)) {
                    if (onComplete != undefined) {
                        onComplete()
                    }
                    return data;
                }
            } catch (e: any) {
                if (onComplete != undefined) {
                    onComplete()
                }
                throw new Error(t('shared.internet_connexion_error'))
            }
            throw new Error(data?.message || data?.detail || t('shared.internet_connexion_error'));
        },
    )
}

const default_users: UserData = {
    pages: 1,
    data: [],
    current_page: 1,
    total: 10

}

export const useCreateUserMutation = (
    setLoading: (loading: boolean) => void,
    onSuccess: () => void
) => {

    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    return useMutation(
        async (values: any) => {
            setLoading(true);
            let data;
            try {
                const response = await BaseService.postRequest(UserUrls.ADD_USER, values, true);
                data = await response.json();
                if ([200, 201].includes(response.status)) {
                    return data;
                }
            } catch (e: any) {
                throw new Error(t('shared.internet_connexion_error'))
            }
            throw new Error(data?.message || data?.detail || t('shared.internet_connexion_error'));
        },
        {
            onError: (error: Error) => {
                enqueueSnackbar(error.message, {
                    variant: 'warning'
                })
            },
            onSuccess: (data: User) => {
                const key = queryClient.getQueryData<UserCrudType>(payload_users_key) || default_users_key;

                queryClient.setQueryData<UserData>(key, (old) => {
                    const users = old || default_users;

                    return {
                        ...users,
                        data: [data, ...(users.data || []),]
                    }
                });

                enqueueSnackbar(t('user_created_success'), {
                    variant: 'success'
                });

                onSuccess();
            },
            onSettled: () => {
                setLoading(false)
            }
        }
    )
}


export const useUpdateUserMutation = (
    setLoading: (loading: boolean) => void,
    onSuccess: () => void
) => {

    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    return useMutation(
        async (values: any) => {
            setLoading(true);
            let data;
            try {
                const response = await BaseService.putRequest(UserUrls.UPDATE_USER, values, true);
                data = await response.json();
                if ([200, 201].includes(response.status)) {
                    return data;
                }
            } catch (e: any) {
                throw new Error(t('shared.internet_connexion_error'))
            }
            throw new Error(data?.message || data?.detail || t('shared.internet_connexion_error'));
        },
        {
            onError: (error: Error) => {
                enqueueSnackbar(error.message, {
                    variant: 'warning'
                })
            },
            onSuccess: (data: User) => {
                const key = queryClient.getQueryData<UserCrudType>(payload_users_key) || default_users_key;

                queryClient.setQueryData<UserData>(key, (old) => {
                    const users = old || default_users;

                    return {
                        ...users,
                        data: [...(old?.data || [])].map(user => user.uuid === data.uuid ? data : user)
                    }
                });

                enqueueSnackbar(t('user_update_success'), {
                    variant: 'success'
                });

                onSuccess();
            },
            onSettled: () => {
                setLoading(false)
            }
        }
    )
}

export const useDeleteUserMutation = (
    setLoading: (loading: boolean) => void,
    onSuccess: () => void
) => {

    const { enqueueSnackbar } = useSnackbar();
    const queryClient = useQueryClient();
    const { t } = useTranslation();

    return useMutation(
        async (uuid: string) => {
            setLoading(true);
            let data;
            try {
                const response = await BaseService.deleteRequest(UserUrls.DELETE_USER(uuid), {}, true);
                data = await response.json();
                if ([200, 201].includes(response.status)) {
                    return { uuid };
                }
            } catch (e: any) {
                throw new Error(t('shared.internet_connexion_error'))
            }
            throw new Error(data?.message || data?.detail || t('shared.internet_connexion_error'));
        },
        {
            onError: (error: Error) => {
                enqueueSnackbar(error.message, {
                    variant: 'warning'
                })
            },
            onSuccess: (data: { uuid: string }) => {
                const key = queryClient.getQueryData<UserCrudType>(payload_users_key) || default_users_key;

                queryClient.setQueryData<UserData>(key, (old) => {
                    const users = old || default_users;

                    return {
                        ...users,
                        data: [...(old?.data || [])].filter(user => user.uuid !== data.uuid)
                    }
                });

                enqueueSnackbar(t('user_delete_success'), {
                    variant: 'success'
                });

                onSuccess();
            },
            onSettled: () => {
                setLoading(false)
            }
        }
    )
}

export const useDefaultReminderTask = (

) => {

    const { t } = useTranslation();
    const { enqueueSnackbar } = useSnackbar();

    return useMutation(async (values: any) => {
        let data: any;
        try {
            const response = await BaseService.putRequest(
                UserUrls.SET_DEFAULT_REMINDER,
                values,
                true
            );
            data = await response.json();
            if ([200, 201].includes(response.status)) {
                enqueueSnackbar(data.message, {
                    variant: 'success'
                })
                return data;
            }
        } catch {
            throw new Error(t('shared.internet_connexion_error'))
        }
        throw new Error(data?.message || data?.detail || t('shared.internet_connexion_error'));
    })

}

export const useUserDetails = (uuid: string) => {
    const { t } = useTranslation();
    return useQuery<User, Error>(
        get_detail_user_key(uuid),
        async ({ pageParam, queryKey: [key, page] }) => {
            let data: any;
            if (!uuid)
                throw new Error(t('UUID not found'))
            try {
                const response = await BaseService.getRequest(
                    UserUrls.DETAILS_USER(uuid),
                    true
                )
                data = await response.json();
                if ([200, 201].includes(response.status)) {
                    return data;
                }
            } catch (e: any) {
                throw new Error(t('shared.internet_connexion_error'))
            }
            throw new Error(data?.message || data?.detail || t('shared.internet_connexion_error'));
        },
    )
}

export const useUserAccessibleDetails = (uuid: string) => {
    const { t } = useTranslation();
    return useQuery<User, Error>(
        get_detail_user_key(uuid),
        async ({ pageParam, queryKey: [key, page] }) => {
            let data: any;
            if (!uuid)
                throw new Error(t('UUID not found'))
            try {
                const response = await BaseService.getRequest(
                    UserUrls.USER_ACCESSIBLE_DETAILS(uuid),
                    true
                )
                data = await response.json();
                if ([200, 201].includes(response.status)) {
                    return data;
                }
            } catch (e: any) {
                throw new Error(t('shared.internet_connexion_error'))
            }
            throw new Error(data?.message || data?.detail || t('shared.internet_connexion_error'));
        },
    )
}

